import React from "react";
import loadable from "@loadable/component";
// import { isMobileOnly } from "react-device-detect";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Hero from "../../components/Hero/hero";
import {
  restaurantHero,
  unifiedExportLargeFeatures,
  restuarantWhiteGlove,
  restuarantWebsite,
  restaurantVideo,
  restaurantCta,
  restaurantFAQs,
  restaurantSubverticals,
  restaurantTestimonials,
  restaurantGiftcardsTestimonials,
  restaurantNav,
  advisoryBoard,
  restaurantArticles,
  integrationV2,
} from "../../data/restaurant-data";
import { giftCardsGeneric } from "../../data/gift-cards";
import { heroSuccess, momoyaSlides } from "../../data/success-stories-data";

import ogImage from "../../images/global_assets/og-image.png";
import integrations from "../../components-v2/IntegrationsOverview/data/restaurant-integrations";

const whiteGloveImport = "rest-white-glove.png";
const InnerNav = loadable(() => import("../../components/InnerNav/InnerNav"));
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/RestaurantPosLF")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const PosSection = loadable(() =>
  import("../../components/PosSection/PosSection")
);
const Subverticals = loadable(() =>
  import("../../components/SubVerticals/Subverticals")
);
const GiftCards = loadable(() =>
  import("../../components/GiftCards/GiftCards")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const IntegrationsOverview = loadable(() =>
  import("../../components-v2/IntegrationsOverview")
);
const RequestIntegration = loadable(() =>
  import("../../components-v2/RequestIntegration")
);
// const IntegrationsSection = loadable(() =>
//   import("../../components/Integrations/IntegrationsSection")
// );
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const Articles = loadable(() => import("../../components/Articles/Articles"));
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const FAQ = loadable(() => import("../../components/FAQs/Faqs"));
// const CapterraBanner = loadable(() =>
//   import("../../components-v2/CapterraBanner")
// );
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

const Restaurants = () => {
  return (
    <Layout className="restaurants">
      <SEO
        title="Restaurant POS System | Point of Sale Software | SpotOn"
        description="SpotOn is the most reliable restaurant POS System. We offer an all-in-one restaurant point of sale system that is easy to use. Get started now."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/restaurant-pos"
        productSchemaSR
      />
      <Hero sectionData={restaurantHero} showPowerPartnerBanner />

      <TopRestaurantsLogos
        className="mt-40 md:mt-0"
        mobileImageName="mobile-capterra-logos-restaurant-pos.png"
        desktopImageName="desktop-capterra-logos-restaurant-pos.png"
      />

      <main>
        <InnerNav links={restaurantNav} getDemoUrl="/restaurant-pos/demo" />
        {/* <LargeFeatures
          sectionData={guyLargeFeatureA}
          style={{ marginTop: "128px" }}
        /> */}
        {/* <CapterraBanner
          className="mt-16 lg:mt-20 mb-20 lg:mb-40"
          imageName="capterra-restaurant-pos.png"
          title={`<span class="text-primary">The #1</span> restaurant POS system according to user reviews<span class="text-primary">.</span>`}
          subtitle="Rated the best in every category by restaurant operators."
          withCta
          ctaLabel="See the results"
          ctaTarget="https://datainsights.capterra.com/p/restaurant-pos/226928/spoton/references?g=na&c=&c=&c=&sc=6321593"
        /> */}
        <IntegrationsOverview
          className="mt-16 md:mt-22"
          title={integrationV2.title}
          subTitle={integrationV2.subTitle}
          integrations={integrations}
        />
        <RequestIntegration
          className="mt-16 md:mt-22 mb-[300px] md:mb-40"
          subTitle={integrationV2.partnerSubTitle}
          title={integrationV2.partnerTitle}
          cta={integrationV2.partnerCta}
        />
        <PosSection sectionData={advisoryBoard} />
        <Subverticals sectionData={restaurantSubverticals} />
        {unifiedExportLargeFeatures.map((sectionData, idx) => (
          <LargeFeatures
            key={sectionData.toString() + idx.toString()}
            sectionData={sectionData}
          />
        ))}
        <LargeFeatures sectionData={restuarantWebsite} />

        <GiftCards
          sectionData={giftCardsGeneric}
          carouselData={restaurantGiftcardsTestimonials}
          sectionNavId="giftcards"
        />
        <VideoSection sectionData={restaurantVideo} />
        {/* <IntegrationsSection */}
        {/*  sectionData={restaurantsIntegrations} */}
        {/*  integrationList={restaurantData} */}
        {/*  showInt */}
        {/*  showReq */}
        {/* /> */}
        <SuccessStories sectionData={heroSuccess} slides={momoyaSlides} />
        <WhiteGlove
          sectionData={restuarantWhiteGlove}
          whiteGloveBg={whiteGloveImport}
        />
        <Articles
          sectionData={restaurantArticles}
          blogFilters={{
            limit: 3,
            include: "tags,authors",
            filter: "tag:Restaurants",
          }}
          cornerStone={false}
          style={{ marginTop: 60 }}
        />
        <LargeCta sectionData={restaurantCta} />
        <FAQ sectionData={restaurantFAQs} />
        <TestmonialReviews sectionData={restaurantTestimonials} />

        <TopRestaurantsLogos />
      </main>
    </Layout>
  );
};

export default Restaurants;
