// eslint-disable-next-line import/prefer-default-export
export const giftCardsGeneric = {
  ctaInfoSecondary: {
    ctaTitle: 'Get E-Gift cards',
    ctaTarget: '/demo',
  },
  sectionNavId: '',
  blockTitle: 'E-Gift cards',
  blockSubTitle:
    'Encourage repeat visits and improve cash flow by letting guests order e-gift cards right from your website. Create your own custom designs that match your restaurant branding, or get concierge support from SpotOn.',
  blockList: [
    'Let customers order e-gift cards right from your website',
    'Get started fast with concierge support from SpotOn',
    'Easily take e-gift cards as a form of payment and check customer balances',
    'Reach new customers beyond brick-and-mortar visitors',
    'Create custom designs that match your restaurant branding',
  ],
  blockImg: 'gift-cards.png',
};
